<template>
  <Layout>
    <div class="saveSaleCourseSale">
      <div class="container">
        <ModalUpload 
          typeLesson="VIDEO" 
          @onMediaSelected="onMediaSelected"
        />
        <modal-categoria></modal-categoria>
        <b-row class="spaceWizzard">
          <b-col cols="12" md="3" class="pt-5" data-anima="top">
            <div class="navigate">
              <div class="title animationOpacity">
                {{ nameCourse }}
                <button
                  v-if="!existParans"
                  id="viewModuleLesson"
                  class="viewModuleLesson d-flex mt-2"
                  @click="viewCourse()"
                >
                  Ver Curso
                  <IconSax class="ml-2" name="redo" type="outline" size="1rem" color="#c4c4c4" />
                </button>
              </div>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/' + cursoAtual"
                class="removeLink"
              >
                <div class="spaceNav">
                  <IconSax name="monitor" />
                  <p class="m-0 text ml-3">Dados do Curso</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto"  />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/' + cursoAtual + '/module'"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="book-square" />
                  <p class="m-0 text ml-3">Módulos e Aulas</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/class/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="profile-2user" />
                  <p class="m-0 text ml-3">Turmas</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/create-certificates/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="medal-star" />
                  <p class="m-0 text ml-3">Certificado</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <!-- <div class="lineDiv"></div>
            <router-link
              :to="'/config_curso/emails/'+cursoAtual+''"
              class="removeLink"
              :class="{ disableLink: existParans }"
            >
              <div class="spaceNav">
                <div class="text"><svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V13C0 13.7956 0.316071 14.5587 0.87868 15.1213C1.44129 15.6839 2.20435 16 3 16H17C17.7956 16 18.5587 15.6839 19.1213 15.1213C19.6839 14.5587 20 13.7956 20 13V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3L10 7.88L2 3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2ZM18 13C18 13.2652 17.8946 13.5196 17.7071 13.7071C17.5196 13.8946 17.2652 14 17 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V5.28L9.48 9.85C9.63202 9.93777 9.80446 9.98397 9.98 9.98397C10.1555 9.98397 10.328 9.93777 10.48 9.85L18 5.28V13Z" fill="#333333"/>
</svg>
  E-mails</div>
                <IconSax name="arrow-right-3" size="1rem"  />
              </div>
            </router-link> -->
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/vendas/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="bag-2" />
                  <p class="m-0 text ml-3">Vendas</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <div class="spaceBtn" v-if="existParans === false">
                <toggle-button
                  :sync="true"
                  :labels="{ checked: 'Ativo', unchecked: 'Inativo' }"
                  @change="enventSwitchStatus"
                  v-model="myDataVariable"
                  :class="{ disableLink: existParans }"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="9" class="pt-5" data-anima="top">
            <div class="containerWizzard">
              <div class="title">Gerenciar Venda</div>
              <p class="desc">
                Configure as informações abaixo para exibir um modal de vendas quando um aluno clicar em um curso que ele não tenha acesso, fornecendo informações adicionais e um botão personalizado para a página de vendas.
              </p>
            </div>
            <div class="containerWizzardInputs">
              <div class="spaceInputs">
                <b-form-group label="Texto do Botão" label-for="name">
                  <b-form-input
                    v-model="textButton"
                    placeholder="Texto do Botão"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="spaceInputs">
                <b-form-group label="Link do Checkout da Voomp (campo obrigatório)" label-for="name">
                  <b-form-input 
                    id="linkSell"
                    name="linkSell" 
                    v-model="linkSell" 
                    placeholder="Link de Venda"
                    v-validate="'required'" />
                  <b-form-invalid-feedback :state="!errors.has('linkSell')">
                    Campo obrigatório para criação do modal
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div class="spaceInputs">
                <b-form-group label="Descrição que aparecerá na thumb do curso" label-for="descricao">
                  <Editor v-model="descricaoCourse" :onChange="onEditorChange" placeholder="Digite aqui a descrição do termo..."/>
                </b-form-group>
              </div>
              <!-- <div class="spaceInputs">
                <b-form-group
                  label="Preview do Curso(Opcional)"
                  label-for="intro"
                >
                  <div
                    class="btn-openModal"
                    @click="showModalUploadIntro"
                    v-if="midiaintroducaopreview === null"
                  >
                    <p class="text">Clique e faça o Upload do Vídeo</p>
                  </div>
                  <div
                    class="btn-openModalPreview"
                    style="height: 180px"
                    @click="showModalUploadIntro"
                    v-else
                  >
                    <img
                      class="imagePreviewYt animationOpacity"
                      :src="midiaintroducaopreview"
                      :alt="midiaintroducaopreview"
                    />
                  </div>
                </b-form-group>
              </div> -->
              <div class="spaceInputs">
                <button class="btn-criar d-flex align-items-center justify-content-center" @click.prevent="saveSaleCourse()">
                  Salvar Dados
                </button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "@/layout/Layout.vue";
import ModalCategoria from "@/components/ModalCategoria.vue";
import CourseService from "@/services/resources/CourseService";
import { ToggleButton } from "vue-js-toggle-button";
import notify from "@/services/libs/notificacao";
import Editor from "@/components/Editor.vue";

const serviceCourse = CourseService.build();

export default {
  components: {
    Layout,
    ModalCategoria,
    ToggleButton,
    Editor,
  },
  data() {
    return {
      nameCourse: "",
      nameModulo: "",
      resumoCourse: "",
      descricaoCourse: "",
      categoryCourse: "",
      btnText: "Criar curso agora",
      step: 0,
      AllCategory: [],
      data: "introducao",
      midiaintroducao: "",
      midiaintroducaopreview: null,
      midiacapa: "",
      midiacapaurl: "",
      midiaaulas: "",
      cursoAtual: "",
      myDataVariable: true,
      existParans: true,
      linkSell: "",
      textButton: "Quero Aproveitar Agora",
      tipoVenda: "modal",
    };
  },
  methods: {
    onMediaSelected(data) {
      if (data.origin == "uploadintroducao") {
        this.midiaintroducaotype = data.type;
        this.midiaintroducao = data.media;
        this.midiaintroducaopreview = data.thumbnail;
      }
    },
    viewCourse() {
      serviceCourse.read(this.cursoAtual + "/module").then((resp) => {
        if (resp.length === 0) {
          notify("erro", "Nenhum Módulo Configurado!");
          return false;
        }
        let routeData = this.$router.resolve("/curso/" + this.cursoAtual);
        window.open(routeData.href, "_blank");
      });
    },
    enventSwitchStatus() {
      var xxxStatus = "";
      if (this.myDataVariable === true) {
        xxxStatus = "published";
      } else {
        xxxStatus = "draft";
      }
      this.$root.$emit("loadOn");
      serviceCourse
        .postID({ id: this.cursoAtual, status: xxxStatus })
        .then((resp) => {
          this.$root.$emit("loadOff");
          this.getCourse();
        })
        .catch((err) => {
          this.$root.$emit("loadOff");
        });
    },
    onEditorChange(value) {
      this.descricaoCourse = value;
    },
    setMetas(id) {
      let data = {
        id: id + "/meta/tipo_venda",
        value: this.tipoVenda,
      };
      serviceCourse
        .postID(data)
        .then((resp) => {
        })
        .catch((err) => {
          var error = JSON.parse(err.response.data);
          var msg = "";
          for (var indice in error) {
            msg += error[indice][0] + "<br>";
          }
          if (msg !== "") {
            notify("erro", msg);
          }
        });
    },
    saveSaleCourse() {
      let data = {
        id: this.cursoAtual + "/sale",
        sale_button_txt: this.textButton,
        sale_link: this.linkSell,
        sale_video: this.midiaintroducao,
        sale_video_thumb: this.midiaintroducaopreview,
        sale_video_type: this.midiaintroducaotype,
        sale_description: this.descricaoCourse,
      };
      this.setMetas(this.cursoAtual);
      this.$root.$emit("loadOn");
      serviceCourse
        .postID(data)
        .then((resp) => {
          this.$root.$emit("loadOff");
          if (!this.linkSell) {
            this.$bvToast.toast("Necessário input de link para criação do modal.", {
              title: "Erro ao configurar modal",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          } else {
            this.$bvToast.toast("Configurações salvas e recurso habilitado.", {
              title: "Recurso habilitado com sucesso",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
        })
        .catch((err) => {
          if (err.response.data.includes("String data, right truncated")) {
            this.$bvToast.toast("Campo descrição não pode ter mais que 65KB", {
              title: "Erro ao configurar modal",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          } else {
            this.$bvToast.toast("Ocorreu um erro desconhecido no servidor", {
              title: "Erro ao configurar modal",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
          this.$root.$emit("loadOff");
        });
    },
    getSaleDados() {
      let url =
        this.cursoAtual +
        "/meta?keys[]=sale_link&keys[]=sale_video&keys[]=sale_video_thumb&keys[]=sale_button_txt&keys[]=sale_description&keys[]=sale_video_type";
      serviceCourse
        .read(url)
        .then((resp) => {
          this.linkSell = resp.sale_link;
          this.midiaintroducao = resp.sale_video;
          this.midiaintroducaopreview = resp.sale_video_thumb;
          this.midiaintroducaotype = resp.sale_video_type;
          this.descricaoCourse = resp.sale_description;
          if (resp.sale_button_txt !== null) {
            this.textButton = resp.sale_button_txt;
          }
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          this.$root.$emit("loadOff");
        });
    },
    getCourse() {
      let idCourse = this.$route.params.curso;
      if (idCourse === null || idCourse === undefined || idCourse === "") {
        this.$router.push("/config_curso");
      } else {
        this.$root.$emit("loadOn");
        this.cursoAtual = idCourse;
        serviceCourse
          .read(idCourse)
          .then((resp) => {
            this.existParans = false;
            this.nameCourse = resp.title;
            if (resp.status == "published") {
              this.myDataVariable = true;
            } else {
              this.myDataVariable = false;
            }
            this.$root.$emit("loadOff");
            this.getSaleDados();
          })
          .catch((err) => {
            this.$root.$emit("loadOff");
          });
      }
    },
    showModalUploadIntro() {
      this.$root.$emit("uploadintroducao");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
  },
  mounted() {
    this.getCourse();
    this.$root.$on("midiaintroducaovimeo", (data) => {
      this.midiaintroducao = data.link;
      this.midiaintroducaopreview = data.pictures.sizes[0].link;
      this.midiaintroducaotype = "vimeo";
    });
    this.$root.$on("midiaintroducaopanda", (data) => {
      this.midiaintroducao = data.cdn_url;
      this.midiaintroducaopreview = data.thumbnail;
      this.midiaintroducaotype = "panda";
    });
    this.$root.$on("midiaintroducaoyt", (data) => {
      this.midiaintroducao =
        "https://www.youtube.com/watch?v=" + data.id.videoId;
      this.midiaintroducaopreview = data.snippet.thumbnails.standard.url;
      this.midiaintroducaotype = "youtube";
    });
  },
};
</script>
<style lang="scss">
.btn-openModalPreview {
  background: var(--maincolortrans);
  border: 2px dashed var(--maincolor);
  box-sizing: border-box;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
  img {
    border-radius: 3px;
  }
  .imagePreviewCapa {
    height: 150px;
  }
}
.saveSaleCourseSale {
  input#rangeCertify {
    border: none !important;
    box-shadow: none !important;
  }
  .textPorcentage {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    margin-top: -10px;
  }
  label {
    color: var(--fontcolor) !important;
  }
  .removeLink {
    text-decoration: none;
  }
  .disableLink {
    pointer-events: none;
  }
  .ql-editor {
    min-height: 100px !important;
  }
  .navigate {
    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      color: var(--fontcolor) !important;
      word-break: break-word;
      margin-bottom: 5px;
    }
    .lineDiv {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      height: 0.5px;
      background: #ededf0;
    }
    .spaceBtn {
      padding: 4px;
      margin-top: 10px;
      .btnDesativar {
        background: rgba(0, 35, 99, 0.1);
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 30px;
        border: none;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #002363;
        height: 45px;
        width: 100%;
      }
    }
    .spaceNav {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      transform: translateY(0px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
      .text {
        font-family: Inter;
        font-style: normal;
        font-size: 15px;
        color: #81858e;
      }
    }
    .spaceNav:hover {
      transform: translateY(2px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
    }
  }
  .editModuleClass {
    position: fixed;
    background: rgba(0, 0, 0, 0.24);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999999999999999999;
    .contentAdd {
      z-index: 99999999999999999999999999;
      position: absolute;
      padding: 30px;
      right: 0;
      top: 25vh;
      width: 303px;
      height: 295px;
      background: #ffffff;
      border: solid 1px var(--bordercolor);
      box-sizing: border-box;
      border-radius: 3px;
      .close {
        cursor: pointer;
      }
      .space {
        margin-top: 40px;
        margin-bottom: 40px;
        input {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 40px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #ededf0;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        input:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        label {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: var(--fontcolor) !important;
        }
        .btn-salvar {
          background: var(--maincolor);
          border-radius: 30px;
          border: none;
          width: 146px;
          font-family: Inter;
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 40px;
        }
      }
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--maincolor);
      }
    }
  }
  .hide {
    display: none !important;
  }
  .spaceWizzard {
    .viewAulas {
      .flexAula {
        display: flex;
        justify-content: space-between;
        .flex {
          display: flex;
          .action {
            position: relative;
            top: 8px;
          }
          .play {
            position: relative;
            top: 6px;
          }
        }
      }
      .textAula {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #c4c4c4;
        margin-left: 20px;
      }
      .timeAula {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #c4c4c4;
      }
      .actionAula {
        display: flex;
        justify-content: space-between;
        svg {
          position: relative;
          left: 24px;
          z-index: 2;
        }
        button.btn.dropdown-toggle.btn-secondary,
        button.btn.dropdown-toggle.btn-secondary:hover,
        button.btn.dropdown-toggle.btn-secondary:focus,
        button.btn.dropdown-toggle.btn-secondary:active,
        button.btn.dropdown-toggle.btn-secondary:focus-within,
        button.btn.dropdown-toggle.btn-secondary:focus-visible {
          background: rgba(255, 255, 255, 0) !important;
          border: rgba(0, 0, 0, 0) !important;
          box-shadow: none !important;
          z-index: 9;
          color: #303e3e00 !important;
        }
        a.dropdown-item {
          font-family: Inter !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          color: var(--fontcolor) !important;
        }
        .dropdown-item:hover,
        .dropdown-item:focus {
          color: var(--fontcolor) !important;
          text-decoration: none !important;
          background-color: #fff !important;
        }
      }
    }
    .blur1 {
      position: fixed;
      left: 1px;
      bottom: -60px;
    }
    .blur2 {
      position: fixed;
      right: 1px;
      top: 80px;
      transform: rotate(180deg);
    }
    .spaceInputsCreate {
      display: flex;
      justify-content: flex-end;
      position: relative;
      left: 5em;
      top: -2em;
      .btn-criar {
        background: var(--maincolor);
        border: 1px solid var(--maincolor);
        box-sizing: border-box;
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 30px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        width: 100%;
        height: 55px;
        margin-top: 40px;
        width: 250px;
      }
    }
    .containerWizzardInputs {
      margin-left: auto;
      margin-right: auto;
      width: 95%;
      margin-top: 50px;
      .paddingNewModule {
        padding-left: 80px;
        padding-right: 80px;
        padding-top: 10px;
      }
      .btn-newModule {
        background: rgba(129, 133, 142, 0.03);
        border: 2px dashed #81858e;
        box-sizing: border-box;
        border-radius: 30px;
        height: 55px;
        cursor: pointer;
        .text {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #81858e;
          display: flex;
          justify-content: center;
          margin-top: 17px;
        }
      }
      .removemb {
        margin-bottom: 0px !important;
      }
      .actionModule {
        display: flex;
        justify-content: space-between;
        .titleModule {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: var(--maincolor);
        }
        svg {
          position: relative;
          left: 24px;
          z-index: 2;
        }
        button.btn.dropdown-toggle.btn-secondary,
        button.btn.dropdown-toggle.btn-secondary:hover,
        button.btn.dropdown-toggle.btn-secondary:focus,
        button.btn.dropdown-toggle.btn-secondary:active,
        button.btn.dropdown-toggle.btn-secondary:focus-within,
        button.btn.dropdown-toggle.btn-secondary:focus-visible {
          background: rgba(255, 255, 255, 0) !important;
          border: rgba(0, 0, 0, 0) !important;
          box-shadow: none !important;
          z-index: 9;
          color: #303e3e00 !important;
        }
        a.dropdown-item {
          font-family: Inter !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          color: var(--fontcolor) !important;
        }
        .dropdown-item:hover,
        .dropdown-item:focus {
          color: var(--fontcolor) !important;
          text-decoration: none !important;
          background-color: #fff !important;
        }
      }
      .lineDiv {
        background: #ededf0;
        transform: matrix(1, 0, 0, -1, 0, 0);
        height: 0.5px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .spaceModuleCard {
        width: 100%;
        background: #ffffff;
        border: 0.5px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 30px;
        padding: 32px;
        margin-bottom: 20px;
      }
      .spaceInputs {
        margin-bottom: 30px;
        .textInfo {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          color: #81858e;
          margin-top: 5px;
        }
        .btn-criar {
          background: var(--maincolor);
          border: 1px solid var(--maincolor);
          box-sizing: border-box;
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          border-radius: 30px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #ffffff;
          width: 100%;
          height: 55px;
          margin-top: 40px;
        }
        .spaceInputs {
          margin-bottom: 30px;
          .textInfo {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: #81858e;
            margin-top: 5px;
          }
          .btn-criar {
            background: var(--maincolor);
            border: 1px solid var(--maincolor);
            box-sizing: border-box;
            box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
            border-radius: 30px;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #ffffff;
            width: 100%;
            height: 55px;
            margin-top: 40px;
          }
          .btn-openModalPreview {
            background: var(--maincolortrans);
            border: 2px dashed var(--maincolor);
            box-sizing: border-box;
            border-radius: 30px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            padding-top: 20px;
            padding-bottom: 20px;
            img {
              border-radius: 3px;
            }
            .imagePreviewCapa {
              width: 100px;
              height: 100px;
              border-radius: 3px;
              overflow: hidden;
            }
          }
          .btn-openModal {
            background: var(--maincolortrans);
            border: 2px dashed var(--maincolor);
            box-sizing: border-box;
            border-radius: 30px;
            height: 55px;
            cursor: pointer;
            .text {
              font-family: Inter;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              color: var(--maincolor);
              display: flex;
              justify-content: center;
              margin-top: 17px;
            }
          }
          .gerenciarCat {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 0.2px;
            color: var(--maincolor);
            position: absolute;
            right: 3em;
            cursor: pointer;
          }
          select {
            border: 1px solid var(--bordercolor);
            box-sizing: border-box;
            border-radius: 30px;
            height: 55px;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #6d767e;
            overflow: hidden !important;
            resize: none !important;
          }
          input,
          textarea {
            border: 1px solid var(--bordercolor);
            box-sizing: border-box;
            border-radius: 30px;
            height: 55px;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #000000;
            overflow: hidden !important;
            resize: none !important;
            background: white;
          }
          input:hover,
          select:hover,
          textarea:hover,
          input:focus,
          select:focus,
          textarea:focus {
            border: 1px solid var(--maincolor);
            box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #000000;
          }
          label {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #000000;
          }
        }
        .btn-openModal {
          background: var(--maincolortrans);
          border: 2px dashed var(--maincolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 55px;
          cursor: pointer;
          .text {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: var(--maincolor);
            display: flex;
            justify-content: center;
            margin-top: 17px;
          }
        }
        .gerenciarCat {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          letter-spacing: 0.2px;
          color: var(--maincolor);
          position: absolute;
          right: 3em;
          cursor: pointer;
        }
        select {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 55px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #6d767e;
          overflow: hidden !important;
          resize: none !important;
        }
        input,
        textarea {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 55px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        select:hover,
        textarea:hover,
        input:focus,
        select:focus,
        textarea:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        label {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
      }
    }
    .containerWizzard {
      text-align: center;
      .desc {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: var(--fontcolor) !important;
        margin: 20px 10px;
      }
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        align-items: center;
        color: var(--fontcolor) !important;
      }
    }
  }
  .containerx {
    width: 98%;
  }
}
</style>
